body {
    background-color: #FAFDFB !important;
    font-size: 15px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    color: #212121;
}

.goog-te-banner-frame{
    top:initial;
    bottom:0;
    display: none;
}

.BetweenTwoSection{
    margin-top: 60px;
    margin-bottom: 60px;
}

.TopSection{
    margin-top: 70px;
}

.Link{
    text-decoration: none !important;
}
.Link:hover{
    text-decoration: none !important;
}
.ph-item{
    border:none !important;
}